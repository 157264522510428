import { useEffect, useState } from "react";
import { Table, Tag, Button, Select, Input, Dropdown } from "antd";
import type { TableProps, MenuProps } from "antd";

import { getReadableFileSizeString } from "../../utils";
import {
  useDeleteFileMutation,
  useGetFilesQuery,
  useLazyDownloadFileQuery,
} from "../../api";

import { useStyles } from "./style";

interface DataType {
  id: string;
  name: string;
  pages: number;
  uploadDate: Date;
  deliveryDate: Date;
  percent: number;
  status: "Pending" | "Ready";
  size: number;
  isReadyToDownload: boolean;
}

const Documents = () => {
  const [status, setStatus] = useState<string>("Status");
  const [order, setOrder] = useState<string>("Uploaded date");
  const [search, setSearch] = useState("");
  const [files, setFiles] = useState<DataType[]>([]);
  const [deleteFile] = useDeleteFileMutation();
  const [getFile, { data: neededFile }] = useLazyDownloadFileQuery();

  function download(url: string) {
    window.open(url);
    // const a = document.createElement("a");
    // a.href = url;
    // a.download = url.split("/").pop()!;
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
  }

  useEffect(() => {
    neededFile && download(neededFile.fileUrl);
  }, [neededFile]);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <p style={{ color: "tomato" }}>Delete</p>,
    },
  ];

  const getColumns = (): TableProps<DataType>["columns"] => {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Pages",
        dataIndex: "pages",
        key: "pages",
      },
      {
        title: "Uploaded date",
        dataIndex: "uploadDate",
        key: "uploadDate",
        render: (item) => <>{item.toLocaleString()}</>,
      },
      {
        title: "Delivery date",
        dataIndex: "deliveryDate",
        key: "deliveryDate",
        render: (item) => <>{item.toLocaleDateString()}</>,
      },
      // {
      //   title: "Percentage of accessibility",
      //   dataIndex: "percent",
      //   key: "percent",
      //   render: (item) => item + "%",
      // },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (item) => (
          <>
            {
              <Tag
                color={item === "pending" ? "#F1F6F9" : "#1BC590"}
                style={{
                  color: item === "pending" ? "#000000" : "",
                  width: 68,
                  textAlign: "center",
                }}
              >
                {item}
              </Tag>
            }
          </>
        ),
      },
      {
        title: "Size",
        dataIndex: "size",
        key: "size",
        render: (item) => getReadableFileSizeString(item),
      },
      {
        title: "Download",
        dataIndex: "download",
        key: "download",
        render: (_, record) =>
          record.isReadyToDownload && (
            <Button type="primary" onClick={() => getFile({ uuid: record.id })}>
              <img src="assets/download.svg" alt="" />
              Accessible version
            </Button>
          ),
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        render: (v) => (
          <Dropdown
            menu={{
              items,
              onClick: () => {
                deleteFile(v.id);
              },
            }}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
          >
            <img src="assets/menuIcon.png" alt="" />
          </Dropdown>
        ),
      },
    ];
  };

  // const testData: DataType[] = useMemo(
  //   () => [
  //     {
  //       id: "1",
  //       name: "doc 1",
  //       pages: 200,
  //       uploadDate: new Date(),
  //       deliveryDate: new Date(),
  //       percent: 89,
  //       status: "Pending",
  //       size: 928340928340,
  //       isReadyToDownload: true,
  //     },
  //     {
  //       id: "2",
  //       name: "doc 2",
  //       pages: 200,
  //       uploadDate: new Date(),
  //       deliveryDate: new Date(),
  //       percent: 89,
  //       status: "Pending",
  //       size: 928340928340,
  //       isReadyToDownload: true,
  //     },
  //   ],
  //   []
  // );

  const classes = useStyles();

  const { data, isLoading } = useGetFilesQuery({
    filterKey: "name",
    filterValue: search,
    orderKey: "uploadDate",
    orderValue: order === "asc" ? "asc" : "desc",
  });

  useEffect(() => {
    const res = data?.map((x: any) => ({
      id: x.fileId,
      name: x.fileName,
      pages: 1,
      uploadDate: x.uploadedAt,
      deliveryDate: new Date(),
      percent: 100,
      status: x.status,
      size: 5555555,
      isReadyToDownload: true,
    }));

    setFiles(res ?? []);
  }, [data]);

  return (
    <div className={classes.wrap}>
      <h3 className={classes.header}>
        <b>Documents</b> / Upload files
      </h3>
      <nav className={classes.nav}>
        <div className={classes.nav_selectors}>
          <Select
            size={"large"}
            defaultValue={status}
            onChange={(e) => setStatus(e)}
            className={classes.status_select}
          >
            <Select.Option value={"Pending"}>{"Pending"}</Select.Option>
            <Select.Option value={"Ready"}>{"Ready"}</Select.Option>
          </Select>
          <Select
            size={"large"}
            className={classes.order_select}
            onChange={(e) => setOrder(e)}
            defaultValue="Uploaded date"
          >
            {["asc", "desc"].map((e, i) => {
              return (
                <Select.Option key={i} value={e}>
                  {e}
                </Select.Option>
              );
            })}
          </Select>
        </div>
        <Input.Search
          size="large"
          prefix={
            <button
              style={{
                border: 0,
                background: "transparent",
                margin: 0,
                marginRight: 8,
                padding: 0,
              }}
            >
              <img src="assets/search.svg" alt="" />
            </button>
          }
          className={classes.search}
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        ></Input.Search>
      </nav>
      <Table
        loading={isLoading}
        pagination={false}
        columns={getColumns()}
        scroll={{ x: 1100 }}
        dataSource={files}
      />
    </div>
  );
};

export { Documents };
