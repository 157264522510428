import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LocalService } from "../utils";

const BASE_URL = "https://lkdkwqpo09.execute-api.us-east-1.amazonaws.com/dev";

export const baseApi = createApi({
  reducerPath: "api",
  tagTypes: ["api", "request"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token =
        LocalService.get("token") || LocalService.get("token", false);

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
    responseHandler: (res) => {
      if (res.status === 401) {
        window.location.replace("/");
        LocalService.removeAll();
      }
      return res.json();
    },
  }),

  endpoints: (build) => ({
    getMe: build.query<any, void>({
      query: () => ({
        url: `/user/me`,
        method: "GET",
      }),
    }),

    upload: build.mutation({
      query: (data: any) => ({
        url: "/file/upload",
        method: "POST",
        body: data,
        formData: true,
      }),
    }),

    upload2: build.mutation({
      query: (data: any) => ({
        url: "/file/upload",
        method: "PUT",
        body: { chunkData: data.chunkData, fileName: data.fileName },
        params: { uploadId: data.uploadId, partNumber: data.partNumber },
        formData: true,
      }),
    }),

    upload3: build.mutation({
      query: (data: any) => ({
        url: "/file/upload",
        method: "PATCH",
        body: { parts: data.parts, fileName: data.fileName },
        params: { uploadId: data.uploadId },
        formData: true,
      }),
    }),

    getFiles: build.query<any, any>({
      query: (args) => {
        const { filterKey, filterValue, orderKey, orderValue } = args;
        return {
          url: "/file",
          method: "GET",
          params: {
            filterKey,
            filterValue,
            orderKey,
            orderValue,
          },
        };
      },
    }),

    downloadFile: build.query<any, any>({
      query: (params) => {
        return {
          url: `/file/${params.uuid}`,
          method: "GET",
        };
      },
    }),

    deleteFile: build.mutation({
      query: (uuid: string) => {
        return {
          url: "/file",
          method: "DELETE",
          params: {
            uuid,
          },
        };
      },
    }),

    login: build.mutation({
      query: (data: any) => ({
        url: "/user/authenticate",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useGetMeQuery,
  useUploadMutation,
  useUpload2Mutation,
  useUpload3Mutation,
  useGetFilesQuery,
  useDeleteFileMutation,
  useLazyDownloadFileQuery,
} = baseApi;
