import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  plans: {
    height: 166,
    borderRadius: 20,
    padding: "32px 0 0 32px",
    position: "relative",
    "& img": {
      borderRadius: 20,
      top: 0,
      left: 0,
      width: "100%",
      height: 198,
      objectFit: "cover",
      position: "absolute",
    },
    "& h3, p, button": {
      position: "inherit",
      zIndex: 100,
    },
    "& p": {
      "@media(max-width: 480px)": {
        width: 130,
        textWrap: "wrap",
        fontSize: 12,
      },
    },
    "& h3": {
      marginTop: 0,
      "@media(max-width: 480px)": {
        fontSize: 16,
      },
    },
  },
  file: {
    position: "relative",
    gap: 8,
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 34,
    "&>div>h4, &>div>p": {
      margin: 0,
    },
  },
  close: {
    fontSize: 26,
    textAlign: "center",
    position: "absolute",
    width: 16,
    height: 16,
    top: -18,
    right: -18,
    display: "flex",
    "&:hover": {
      cursor: "pointer",
    },
  },
});
