import { createSlice } from "@reduxjs/toolkit";

export interface IUser {
  createdAt: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  mobileNumber: string;
  profileImage?: string;
  userType: string;
}

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {
      createdAt: "",
      email: "",
      firstName: "",
      id: "",
      lastName: "",
      mobileNumber: "",
      profileImage: "",
      userType: "",
    } as IUser,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice;
