import { Card, List } from "antd";
import { useStyles } from "./style";
import { FC } from "react";
import { getReadableFileSizeString } from "../../utils";

interface FileListProps {
  fileList: any;
  setFileList: React.Dispatch<React.SetStateAction<any[]>>;
}

const FileList: FC<FileListProps> = ({ fileList, setFileList }) => {
  const classes = useStyles();
  const removeFile = (e: any) => {
    setFileList(fileList.filter((file: any) => file.uid !== e.uid && file));
  };

  return (
    <>
      {/* {fileList.length === 0 ? (
        <div className={classes.plans}>
          <img src="assets/upload_docs.png" alt="" />
          <h3>Have a lot of documents? No problem!</h3>
          <p>Choose your plan and start making your documents accessible</p>
          <Button type="primary" size="large">
            Explore plans
          </Button>
        </div>
      ) : ( */}
      {fileList.length > 0 && (
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 4,
            xxl: 4,
          }}
          dataSource={fileList}
          renderItem={(item: any) => (
            <List.Item>
              <Card style={{ background: "#F1F6F9", border: "none" }}>
                <div className={classes.file}>
                  <img src="assets/fileImg.png" alt="" />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h4>{item.name}</h4>
                    <p style={{ width: 150 }}>
                      {getReadableFileSizeString(item.size)}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      removeFile(item);
                    }}
                    className={classes.close}
                  >
                    <img src="assets/close.svg" alt="" />
                  </div>
                </div>
              </Card>
            </List.Item>
          )}
        />
      )}
      {/* )} */}
    </>
  );
};

export default FileList;
