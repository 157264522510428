import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  upload: {
    padding: 24,
    gap: 16,
    borderRadius: 20,
    border: "1px solid #F1F6F9",
    boxShadow: "0px 1px 4px 0px #ECECF4",
  },
  upload_text: {
    fontSize: 24,
    fontWeight: 700,
  },
  upload_hint: {
    fontSize: 14,
    fontWeight: 400,
  },
  or: {
    color: "#99A9B2",
    position: "relative",
    "&:before": {
      position: "absolute",
      top: "50%",
      left: "calc(50% - 90px - 16px)",
      content: '""',
      background: "#F1F6F9",
      height: 1,
      width: 90,
      "@media(max-width: 320px)": {
        width: 50,
        left: "calc(50% - 50px - 16px)",
      },
    },
    "&:after": {
      position: "absolute",
      top: "50%",
      right: "calc(50% - 90px - 16px)",
      content: '""',
      background: "#F1F6F9",
      height: 1,
      width: 90,
      "@media(max-width: 320px)": {
        width: 50,
        right: "calc(50% - 50px - 16px)",
      },
    },
  },
  choose_button: {
    padding: "15px 20px",
    borderRadius: 12,
    background: "#9D95E5",
    border: 0,
    color: "#FFFFFF",
    fontSize: 14,
    "&:hover": {
      cursor: "pointer",
    },
  },
  submit_buttons: {
    height: 50,
    display: "flex",
    alignItems: "center",
    position: "relative",
    justifyContent: "space-between",
    "&>p": {
      fontSize: 14,
      fontWeight: 400,
      padding: "12px 20px",
      textAlign: "center",
      color: "#445C69",
      "&:hover": {
        cursor: "pointer",
      },
    },
    "&>button": {
      position: "absolute",
      top: 0,
      left: "calc(50% - 47px)",
      padding: "15px 20px",
      borderRadius: 12,
      width: 94,
      height: 48,
      "@media(max-width: 320px)": {
        position: "initial",
      },
    },
  },
});
