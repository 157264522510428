export const LocalService = {
  set: (key: string, value: any, isLocalStorage = true) => {
    if (isLocalStorage) {
      return localStorage.setItem(key, value);
    }
    sessionStorage.setItem(key, value);
  },
  get: (key: string, isLocalStorage = true) => {
    if (isLocalStorage) {
      return localStorage.getItem(key);
    }
    return sessionStorage.getItem(key);
  },
  delete: (key: string, isLocalStorage = true) => {
    if (isLocalStorage) {
      return localStorage.removeItem(key);
    }
    return sessionStorage.removeItem(key);
  },
  removeAll: (isLocalStorage = true) => {
    if (isLocalStorage) {
      return localStorage.clear();
    }
    sessionStorage.clear();
  },
};
export function getReadableFileSizeString(fileSizeInBytes: number) {
  var i = -1;
  var byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
  do {
    fileSizeInBytes /= 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}
