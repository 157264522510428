import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  wrap: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  center: {
    display: "flex",
    border: "1px solid #ECF1F3",
    borderRadius: "20px",
    boxShadow: "0px 3px 21.8px 0px rgba(224, 229, 234, 0.2)",
    "@media(max-width: 1080px)": {
      width: 1000,
    },
    "@media(max-width: 960px)": {
      width: 880,
    },
    "@media(max-width: 768px)": {
      width: 680,
    },
    "@media(max-width: 576px)": {
      width: 500,
    },
    "@media(max-width: 480px)": {
      width: 440,
    },
    "@media(max-width: 320px)": {
      width: 280,
    },
  },
  logo: {
    position: "absolute",
    top: 32,
    left: 64,
  },
  left: {
    width: 448,
    padding: "143px 64px",
    flex: "50%",
    "@media(max-width: 480px)": {
      padding: "143px 48px",
    },
    "@media(max-width: 320px)": {
      padding: "143px 16px",
    },
  },
  title: {
    margin: "0 0 32px",
  },
  image: {
    objectFit: "cover",
    width: 576,
    height: "100%",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    "@media(max-width: 1080px)": {
      width: 500,
    },
    "@media(max-width: 960px)": {
      width: 440,
    },
    "@media(max-width: 768px)": {
      display: "none",
    },
  },
});
