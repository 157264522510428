import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  layout: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    padding: 32,
    gap: 32,
    overflowY: "scroll",
    height: "calc(100vh - 140px)",
    width: "100%",
  },
  header: {
    margin: 0,
    fontWeight: 400,
    fontSize: 16,
  },
  wrap: {
    border: "1px solid #ECF1F3",
    padding: 24,
    display: "flex",
    flexDirection: "column",
    gap: 32,
    boxShadow: "0px 1px 4px 0px #ECECF4",
    borderRadius: 20,
 

  },
  making: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 32,
    padding: "40px 24px",
    "&>h2": {
      fontSize: 24,
      fontWeight: 700,
    },
  },
});
