import type { UploadProps } from "antd";
import { Upload as upload, Button } from "antd";
import { useStyles } from "./style";
import { FC } from "react";
const { Dragger } = upload;

interface FileUploaderProps {
  handleNext: (file: any) => void;
  setFileList: React.Dispatch<any>;
  fileList: any[];
}

const FileUploader: FC<FileUploaderProps> = ({
  handleNext,
  setFileList,
  fileList,
}) => {
  const classes = useStyles();

  const props: UploadProps = {
    name: "file",
    multiple: true,
    fileList,
    showUploadList: false,
    customRequest: () => {},
    onChange(info) {
      setFileList(info.fileList);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  return (
    <>
      {
        <Dragger
          className={classes.upload}
          style={{ border: 0, background: "white" }}
          {...props}
        >
          <p className={classes.upload_text}>Upload your files</p>
          <img src={"assets/Upload.png"} alt="" />
          <p className={classes.upload_hint}>
            Drag & drop PDF or WORD files here or{" "}
            <b>
              <u>Choose file</u>
            </b>
          </p>
          {fileList.length === 0 && (
            <>
              <p className={classes.or}>OR</p>
              <button className={classes.choose_button}>Choose file</button>
            </>
          )}
        </Dragger>
      }

      {fileList.length > 0 && (
        <div className={classes.submit_buttons}>
          <p onClick={() => setFileList([])}>Cancel</p>
          <Button
            onClick={() => {
              handleNext(fileList);
            }}
            type="primary"
            size={"large"}
          >
            Next
          </Button>
        </div>
      )}
    </>
  );
};

export default FileUploader;
