import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/user";
import { baseApi } from "../api";
import filesSlice from "./slices/files";

const store = configureStore({
  reducer: {
    userSlice: userSlice.reducer,
    filesSlice: filesSlice.reducer,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware),
});

export type IRootState = ReturnType<typeof store.getState>;

export default store;
