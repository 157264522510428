import { useGetMeQuery } from "../../api";
import { Logo } from "../../assets/icon";
import { useStyles } from "./style";
import Avatar from "react-avatar";

const Header = () => {
  const classes = useStyles();
  const { data } = useGetMeQuery();

  return (
    <div className={classes.wrap}>
      <div>
        <Logo />
      </div>
      {/* <div className={classes.logo}>LOGO</div> */}
      {data?.avatar ? (
        <Avatar size="40" round="20px" src={data.avatar} name={data?.fullName ?? "TT"} />
      ) : (
        <Avatar
          size="40"
          round="20px"
          name={data?.fullName ?? "TT"}
          value="5%"
        />
      )}
    </div>
  );
};

export { Header };
