import React, { FC } from "react";
import { useStyles } from "./style";

interface MenuButtonProps {
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const MenuButton: FC<MenuButtonProps> = ({ setIsVisible }) => {
  const classes = useStyles();

  return (
    <header>
      <button className={classes.menuButton} onClick={() => setIsVisible(true)}>
        <img style={{ width: 24 }} src="assets/menu.svg" alt="" />
        <span>Menu</span>
      </button>
    </header>
  );
};

export default MenuButton;
