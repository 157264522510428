import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  wrap: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 3px 21.8px 0px rgba(224, 229, 234, 0.2)",
    padding: "8px 28px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
  },
  logo: {
    fontSize: 14,
    color: "#A7A7B0",
  },
});
