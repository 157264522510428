import { Button, Menu, MenuProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { Doc, Web } from "../../assets/icon";
import { useStyles } from "./style";
import { FC } from "react";
import { LocalService } from "../../utils";

type MenuItem = Required<MenuProps>["items"][number];

interface SidebarProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const items: MenuItem[] = [
  {
    key: "/home",
    label: "Documents",
    icon: <Doc />,
    children: [
      { key: "/upload", label: "Upload file", style: { height: 26 } },
      { key: "/documents", label: "My documents", style: { height: 26 } },
    ],
  },
  {
    key: "/scanner",
    label: "Website’s PDF scanner",
    icon: <Web />,
  },
];

const Sidebar: FC<SidebarProps> = ({ isVisible, setIsVisible }) => {
  const classes = useStyles();
  const nav = useNavigate();
  const localion = useLocation();

  const onClick: MenuProps["onClick"] = (e) => {
    nav(e.key);
  };

  return (
    <>
      <div
        className={classes.wrap}
        style={{ display: isVisible ? "inline-block" : "none" }}
      >
        <p onClick={() => setIsVisible(false)}>&times;</p>
        <Menu
          onClick={onClick}
          style={{ width: 197 }}
          mode="inline"
          items={items}
          defaultOpenKeys={["/home"]}
          defaultSelectedKeys={[localion.pathname]}
          className={classes.menu}
        />
      </div>
      <Button
        type="primary"
        htmlType="submit"
        size="large"
        style={{ width: 197, position: "absolute", bottom: 24, left: 24 }}
        onClick={() => {
          LocalService.removeAll();
          nav("/");
        }}
      >
        Log Out
      </Button>
    </>
  );
};

export { Sidebar };
