import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  menuButton: {
    display: "none",
    alignItems: "center",
    margin: "32px 32px 0",
    padding: "5px 10px",
    gap: 8,
    background: "transparent",
    borderRadius: 8,
    border: "1px solid #d9d9d9",
    "&:hover": {
      cursor: "pointer",
    },
    "@media(max-width: 720px)": {
      display: "flex",
    },
  },
});
