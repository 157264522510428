import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  wrap: {
    backgroundColor: "#ECF1F3",
    height: "calc(100vh - 124px)",
    display: "inline-block",
    padding: 24,
    "@media(max-width: 720px)": {
      paddingTop: 48,
      position: "absolute",
      zIndex: 200,
      left: 0,
      right: 0,
      top: 76,
    },

    "& > p": {
      top: 12,
      right: 32,
      display: "flex",
      margin: 0,
      fontSize: 28,
      padding: 0,
      position: "absolute",
      "&:hover": { cursor: "pointer" },
      "@media(min-width: 721px)": {
        display: "none",
      },
    },
  },
  menu: {
    background: "transparent",
    border: "0 !important",
    "@media(max-width: 720px)": {
      // display: "none",
      width: "100% !important",
    },
    "& .ant-menu-submenu-open .ant-menu-submenu-title": {
      backgroundColor: "white",
      paddingLeft: "9px !important",
    },
    "& .ant-menu-submenu-open .ant-menu": {
      backgroundColor: "transparent !important",
    },
    "& .ant-menu-root": {
      backgroundColor: "transparent !important",
    },
    "& > li.ant-menu-item": {
      paddingLeft: "9px !important",
    },
    "& .ant-menu-submenu-title": {
      paddingLeft: "9px !important",
    },
    "& .ant-menu-sub li": {
      paddingLeft: "9px !important",
      marginLeft: "35px",
      width: "80%",
    },
    "& .ant-menu-submenu-open.ant-menu-submenu::before": {
      top: "155px",
      left: "43px",
      content: `" "`,
      position: "absolute",
      height: "55px",
      width: "2px",
      backgroundColor: "#D8E2E6",
    },
    "& li.ant-menu-item-selected": {
      backgroundColor: "#99A9B2",
      color: "#FFFFFF",
    },
    "& .ant-menu-submenu-selected .ant-menu-submenu-title": {
      backgroundColor: "#FFFFFF",
      color: "#151519",
    },
  },
});
