import { useState } from "react";
import { useStyles } from "./style";
import FileUploader from "../../сomponents/fileUploader";
import FileList from "../../сomponents/fileList";
import {
  useUpload2Mutation,
  useUpload3Mutation,
  useUploadMutation,
} from "../../api";

const CHUNK_SIZE = 5 * 1024 * 1024; //1mb

const Upload = () => {
  const classes = useStyles();
  const [upload, { error, isSuccess }] = useUploadMutation();
  const [upload2] = useUpload2Mutation();
  const [upload3] = useUpload3Mutation();
  const [fileList, setFileList] = useState<any[]>([]);

  const handleNext = (fileList: any) => {
    fileList.forEach((element: any) => {
      upload({ fileName: element.name, mimeType: element.type }).then((v) => {
        const totalChunks = Math.ceil(element.size / CHUNK_SIZE);
        console.log("Total Chunks:", totalChunks);
        let currentChunk = 0;
        const parts: any = [];

        function uploadChunk(chunkData: any, partNumber: any) {
          const base64Chunk = btoa(chunkData);
          upload2({
            chunkData: base64Chunk,
            fileName: element.name,
            uploadId: v.data.uploadId,
            partNumber: currentChunk + 1,
          }).then((response: any) => {
            if (response.data) {
              parts.push({
                PartNumber: currentChunk + 1,
                ETag: response.data.ETag,
              });

              console.log(`CHUNK ${partNumber} was uploaded`);

              currentChunk++;

              if (currentChunk < totalChunks) {
                processNextChunk();
              } else {
                if (response.data.ETag) {
                  upload3({
                    uploadId: v.data.uploadId,
                    parts,
                    fileName: element.name,
                  });
                }
                console.log("Full file was uploaded");
              }
            } else {
              console.error(`Error chunk ${partNumber}`);
            }
          });
        }

        function processNextChunk() {
          const start = currentChunk * CHUNK_SIZE;
          const end = Math.min(start + CHUNK_SIZE, element.size);
          const chunk = element.originFileObj.slice(start, end);
          const reader = new FileReader();
          reader.onload = function (e: any) {
            uploadChunk(e.target.result, currentChunk + 1);
          };
          reader.readAsBinaryString(chunk);
        }

        processNextChunk();
      });
    });
  };

  return (
    <div className={classes.layout}>
      <h3 className={classes.header}>
        <b>Documents</b> / Upload files
      </h3>
      <main className={classes.wrap}>
        {!isSuccess ? (
          <>
            <FileUploader
              fileList={fileList}
              setFileList={setFileList}
              handleNext={handleNext}
            />
            <FileList fileList={fileList} setFileList={setFileList} />
          </>
        ) : (
          <div className={classes.making}>
            <h2>We are making your documents accessible</h2>
            <img
              style={{ width: 77, height: 60 }}
              src={"assets/Upload.png"}
              alt=""
            />
          </div>
        )}
        <p style={{ color: "tomato", margin: 0, padding: 0 }}>
          {error ? (error as any)?.error : ""}
        </p>
      </main>
    </div>
  );
};

export { Upload };
