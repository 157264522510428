import { useNavigate } from "react-router-dom";
import { Button, Form, FormProps, Input } from "antd";
import { useLoginMutation } from "../../api";
import { Logo, Mail, Lock } from "../../assets/icon";
import { useStyles } from "./style";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { LocalService } from "../../utils";

const Login = () => {
  const [login, { error, isSuccess }] = useLoginMutation();
  const [isError, setIsError] = useState(false);

  const nav = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    isSuccess && nav("/upload");
  }, [isSuccess, nav]);

  const onFinish: FormProps["onFinish"] = (values) => {
    setIsError(false);
    login(values).then((v: any) => {
      LocalService.set("token", v.data.accessToken);
    });
  };

  const onFinishFailed: FormProps["onFinishFailed"] = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className={classes.wrap}>
      <Logo className={classes.logo} />
      <div className={classes.center}>
        <div className={classes.left}>
          <Title className={classes.title}>Log In</Title>
          <Form
            name="login"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input prefix={<Mail />} style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password prefix={<Lock />} />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{ width: "100%" }}
              >
                Log In
              </Button>
            </Form.Item>
            <Form.Item>
              <p>{isError && (error as any)?.error}</p>
            </Form.Item>
          </Form>
        </div>
        <div>
          <img className={classes.image} src="./assets/Section.png" alt="img" />
        </div>
      </div>
    </div>
  );
};

export { Login };
