import {
  Route,
  Routes as DomRoutes,
  RouteProps,
  BrowserRouter,
} from "react-router-dom";

import { RequireAuth } from "./AuthGuard";
import { LayoutLogin } from "./LayoutLogin";
import { Upload } from "./upload";
import { Documents } from "./documents";
import { Scanner } from "./scanner";
import { Login } from "./login";

export enum PagesEnum {
  UPLOAD = "/upload",
  DOCUMENTS = "/documents",
  SCANNER = "/scanner",
  LOGIN = "/",
}

export interface IROUTE_MODEL<T> {
  path: string;
  element: T;
}

export const PUBLIC: IROUTE_MODEL<RouteProps["children"]>[] = [
  { path: PagesEnum.LOGIN, element: <Login /> },
];

export const PRIVATE: IROUTE_MODEL<RouteProps["children"]>[] = [
  { path: PagesEnum.UPLOAD, element: <Upload /> },
  { path: PagesEnum.DOCUMENTS, element: <Documents /> },
  { path: PagesEnum.SCANNER, element: <Scanner /> },
];

const Pages = () => {
  return (
    <BrowserRouter>
      <DomRoutes>
        {/* INFO: ALL PRIVATE PATHS */}
        <Route element={<RequireAuth />}>
          {PRIVATE.map(({ path, element }, index) => (
            <Route element={element} key={index} path={path} />
          ))}
        </Route>

        {/* INFO: ALL PUBLIC PATHS */}
        <Route element={<LayoutLogin />}>
          {PUBLIC.map(({ path, element }, index) => (
            <Route element={element} key={index} path={path} />
          ))}
        </Route>
      </DomRoutes>
    </BrowserRouter>
  );
};

export { Pages };
