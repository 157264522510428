import { Outlet } from "react-router-dom";

export const LayoutLogin = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    }}
  >
    <Outlet />
  </div>
);
