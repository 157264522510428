import ReactDOM from "react-dom/client";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./storage/store";

import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: { fontFamily: "DM Sans" },
        components: {
          Input: {
            colorPrimary: "#D8E2E6",
            activeBorderColor: "#D8E2E6",
            hoverBorderColor: "#D8E2E6",
            colorBorderSecondary: "#D8E2E6",
            lineHeight: 3.5,
          },
          Button: {
            colorPrimary: "#9D95E5",
            colorPrimaryHover: "#887fd9",
            paddingBlockLG: 15,
            lineHeight: 15,
          },
          Select: {
            colorPrimary: "#D8E2E6",
            colorBorderSecondary: "#D8E2E6",
          },
        },
      }}
    >
      <App />
    </ConfigProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
