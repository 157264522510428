import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  wrap: {
    overflowY: "scroll",
    height: "calc(100vh - 140px)",
    display: "flex",
    flexDirection: "column",
    padding: 32,
    overflow: "hidden",
    gap: 32,
    width: "100%",
    "& .ant-input-wrapper": {
      display: "flex",
      flexDirection: "row-reverse",
      "& .ant-input-group-addon": {
        display: "none",
      },
      "& .ant-input-affix-wrapper": {
        borderRadius: 8,
        border: "1px solid #F1F6F9",
      },
      "& input.ant-input.ant-input-lg::placeholder ": {
        color: "#2B2B32",
      },
      "& .ant-select-selector": {
        border: "1px solid #F1F6F9",
      },
    },

    "@media(max-width: 576px)": {
      height: "calc(100vh - 140px)",
    },
  },
  nav: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 16,
    "@media(max-width: 768px)": {
      gap: 16,
      flexWrap: "wrap",
    },
  },
  nav_selectors: {
    display: "flex",
    gap: 16,
    "@media(max-width: 320px)": {
      alignItems: "flex-start",
      flexDirection: "column",
      width: 240,
    },
  },
  status_select: {
    width: 148,
    height: 36,
    "@media(max-width: 320px)": {
      width: 240,
    },
  },
  order_select: {
    width: 168,
    height: 36,
    "@media(max-width: 320px)": {
      width: 240,
    },
  },
  search: {
    width: 200,
    "@media(max-width: 320px)": {
      width: 240,
    },
  },
  header: {
    margin: 0,
    width: "100%",
    fontWeight: 400,
    fontSize: 16,
  },
});
