import { useLocation, Navigate, Outlet } from "react-router-dom";
import { PagesEnum } from ".";
import { Header } from "../сomponents/header";
import { LocalService } from "../utils";
import { Sidebar } from "../сomponents/sidebar";
import MenuButton from "../сomponents/menuButton";
import { useState } from "react";

export const RequireAuth = () => {
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(window.innerWidth > 720);

  const isHasToken =
    LocalService.get("token") || LocalService.get("token", false);

  if (!isHasToken) {
    return <Navigate state={{ from: location }} to={PagesEnum.LOGIN} />;
  } else {
    return (
      <>
        <Header />
        <MenuButton setIsVisible={setIsVisible} />
        <div style={{ display: "flex" }}>
          <Sidebar isVisible={isVisible} setIsVisible={setIsVisible} />
          <Outlet />
        </div>
      </>
    );
  }
};
